import React from 'react';
import PropTypes from 'prop-types';

export const IconPlus = ({ className, size }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7 0H5v5H0v2h5v5h2V7h5V5H7V0Z" fill="#fff" />
  </svg>
);

IconPlus.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

IconPlus.defaultProps = {
  className: null,
  size: '12',
};
