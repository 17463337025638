import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import './deposit-button-mobile.scss';
import Button from 'reactstrap/lib/Button';
import { withModalActions, withUser } from 'core/hocs';
import { USER_FIELDS } from 'core/constants';
import { IconPlus } from 'components/icons/icon-plus/icon-plus';
import { MODAL_IDS } from '../../constants';

const setLayout = colums => document.documentElement.style.setProperty('--navigation-items', `${colums}`);

export const DepositButtonMobileUI = ({ openModal, isSignUpCompleted, isUserLoggedIn }) => {
  const handleModalOpen = useCallback(() => {
    openModal(isSignUpCompleted
      ? MODAL_IDS.DEPOSIT
      : MODAL_IDS.COMPLETE_SIGN_UP);
  }, [isSignUpCompleted]);

  useEffect(() => {
    const navItemsCount = isUserLoggedIn ? 5 : 4;

    setLayout(navItemsCount);

    return () => setLayout(4);
  }, [isUserLoggedIn]);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Button
        onClick={handleModalOpen}
        color="transparent"
        className="deposit-button d-flex justify-content-center align-items-center"
        size="xl"
      >
        <div className="deposit-button-border d-flex justify-content-center align-items-center rounded-circle ">
          <div className="deposit-button-content d-flex justify-content-center align-items-center rounded-circle">
            <IconPlus size="16" />
          </div>
        </div>
      </Button>
    </div>
  );
};

DepositButtonMobileUI.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  isSignUpCompleted: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
};

DepositButtonMobileUI.defaultProps = {};

export const DepositButtonMobile = withModalActions(withUser(
  DepositButtonMobileUI, [USER_FIELDS.IS_SIGN_UP_COMPLETED, USER_FIELDS.IS_USER_LOGGED_IN]
));
