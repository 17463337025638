import { IconGamblersAnonymous } from 'components/icons/icons-footer/icon-gamblers-anonymous';
import { IconGamecare } from 'components/icons/icons-footer/icon-gamecare';
import { IconFacebook } from 'components/icons/icons-social/icon-facebook';
import { IconInstagram } from 'components/icons/icons-social/icon-instagram';
import { IconTwitter } from 'components/icons/icons-social/icon-twitter';
import { IconTiktok } from 'components/icons/icons-social/icon-tiktok';
import { IconPinterest } from 'components/icons/icons-social/icon-pinterest';
import { IconTelegram } from 'components/icons/icons-social/icon-telegram';
import { IconYoutube } from 'components/icons/icons-social/icon-youtube';

// import { MODAL_IDS } from '../../constants';

export const footerMenu = [
  {
    title: 'footer.link.games',
    link: '/games',
  },
  {
    title: 'footer.link.live',
    link: '/games/live',
  },
  {
    title: 'footer.link.slot-games',
    link: '/games/slot',
  },
  // {
  //   title: 'footer.link.tournaments',
  //   link: '/tournaments',
  // },
  {
    title: 'footer.link.promos',
    link: '/promos',
  },
  // {
  //   title: 'footer.link.loyalty',
  //   link: '/loyalty',
  // },
  // {
  //   title: 'footer.link.support',
  //   modalId: MODAL_IDS.SUPPORT,
  // },
  // {
  //   title: 'footer.link.anti-money-laundering',
  //   link: '/anti-money-laundering',
  // },
];

export const footerInfo = [
  [
    {
      title: 'footer.link.loyalty',
      link: '/loyalty',
    },
    // {
    //   title: 'footer.link.support',
    //   modalId: MODAL_IDS.SUPPORT,
    // },
    {
      title: 'footer.link.anti-money-laundering',
      link: '/anti-money-laundering',
    },
    {
      title: 'footer.link.about',
      link: '/about',
    },
    {
      title: 'footer.link.terms',
      link: '/terms',
    },
    // {
    //   title: 'footer.link.faq',
    //   link: '/faq',
    // },
    {
      title: 'footer.link.privacy-policy',
      link: '/privacy-policy',
    },
    {
      title: 'footer.link.affiliates',
      link: '/affiliate',
    },
    {
      title: 'footer.link.responsible-gaming',
      link: '/responsible-gaming',
    },
    {
      title: 'footer.link.payments',
      link: '/payments',
    },
    {
      title: 'footer.link.license',
      link: '/license',
    },
    // {
    //   title: 'footer.link.bonus-terms',
    //   link: '/bonus-terms',
    // },
    // {
    //   title: 'footer.link.aml',
    //   link: '/anti-money-laundering',
    // },
    // {
    //   title: 'footer.link.refund-policy',
    //   link: '/refund-policy',
    // },
  ],
  // [

  //   {
  //     title: 'footer.link.self-exclusion',
  //     link: '/self-exclusion',
  //   },
  //   {
  //     title: 'footer.link.dispute-resolution',
  //     link: '/dispute-resolution',
  //   },
  //   {
  //     title: 'footer.link.fairness-and-rng',
  //     link: '/fairness-and-rng',
  //   },
  //   {
  //     title: 'footer.link.kyc',
  //     link: '/kyc',
  //   },
  // ]
];

export const footerIconsWithLink = [
  {
    title: 'Gamecare',
    Icon: IconGamecare,
    link: 'https://www.gamblingtherapy.org/en',
  },
  {
    title: 'GamblersAnonymous',
    Icon: IconGamblersAnonymous,
    link: 'https://www.gamblersanonymous.org.uk',
  },
];

export const getFooterSocialIconsWithLink = formatMessage => [
  {
    id: 'facebook',
    Icon: IconFacebook,
    link: formatMessage({
      id: 'footer.social.url.facebook',
      defaultMessage: 'none',
    }),
  },
  {
    id: 'twitter',
    Icon: IconTwitter,
    link: formatMessage({
      id: 'footer.social.url.twitter',
      defaultMessage: 'none',
    }),
  },
  {
    id: 'instagram',
    Icon: IconInstagram,
    link: formatMessage({
      id: 'footer.social.url.instagram',
      defaultMessage: 'none',
    }),
  },
  {
    id: 'tiktok',
    Icon: IconTiktok,
    link: formatMessage({
      id: 'footer.social.url.tiktok',
      defaultMessage: 'none',
    }),
  },
  {
    id: 'pinterest',
    Icon: IconPinterest,
    link: formatMessage({
      id: 'footer.social.url.pinterest',
      defaultMessage: 'none',
    }),
  },
  {
    id: 'telegram',
    Icon: IconTelegram,
    link: formatMessage({
      id: 'footer.social.url.telegram',
      defaultMessage: 'none',
    }),
  },
  {
    id: 'youtube',
    Icon: IconYoutube,
    link: formatMessage({
      id: 'footer.social.url.youtube',
      defaultMessage: 'none',
    }),
  },
  // {
  //   id: 'whatsapp',
  //   Icon: IconWhatsapp,
  //   link: formatMessage({ id: 'footer.social.url.whatsapp', defaultMessage: 'none' }),
  // },
];

export const footerLogoIcons = [
  {
    name: 'segob',
    imgPath: '/assets/public/images/footer-icons/logos/segob.png',
  },
  {
    name: 'responsible-gambling-en',
    imgPath: '/assets/public/images/footer-icons/logos/responsible-gambling-en.svg',
  },
  {
    name: 'responsible-gambling-mx',
    imgPath: '/assets/public/images/footer-icons/logos/responsible-gambling-mx.svg',
  },
  {
    name: 'secure',
    imgPath: '/assets/public/images/footer-icons/logos/secure.png',
  },
  {
    name: 'SSL',
    imgPath: '/assets/public/images/footer-icons/logos/SSL.png',
  },
  {
    name: '18',
    imgPath: '/assets/public/images/footer-icons/logos/18.svg',
  },
];
